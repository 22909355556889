import { Box } from '@mui/material'

import styled from 'styled-components'

export const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    ${({ theme }) => theme.typography.h1};
    font-weight: 600;

    @media (max-width: 1024px) {
      text-align: center;
      padding-top: 16px;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
    }
  }

  .text {
    margin-top: 0.8rem;
    ${({ theme }) => theme.typography.textMain};
    line-height: 2.2rem;
    font-weight: 300;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .text-field-label {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.typography.textSecondary};
    white-space: initial;
    margin-bottom: 0.8rem;

    &-error {
      color: #c8102e;
    }
  }

  .form {
    &-col {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      width: 65.2rem;

      @media (max-width: 1200px) {
        width: 55.8rem;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }

      &-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
    }

    &-item {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }

      &-full {
        flex-basis: 100%;
      }
    }
  }

  .tooltip-row {
    display: flex;
    align-items: center;
    color: #6f6f6f;
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;

    span {
      margin-left: 4px;
      color: #bb945c;
      font-weight: 450;
    }
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;

    &-price {
      color: #1d1d1d;
    }
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }

  .no-items {
    padding: 30px 32px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 24px;
    }

    &-text {
      text-align: center;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      color: #6f6f6f;
      margin-bottom: 16px;
    }

    &-button {
      width: 100%;
      padding: 10px 0px;
    }

    &-button-container {
      width: 100%;
    }
  }

  .add-form {
    width: 100%;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #1d1d1d;
      }
    }

    &-delete {
      padding: 4px 30px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      min-width: auto;
      margin-left: 30px;
    }
  }
`
