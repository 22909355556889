import { useLocation } from 'react-router'

import { useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'

import Header from '../Header'
import MobileFooter from '../Mobile/Footer'
import MobileHeader from '../Mobile/Header'
import MobileProgressBar from '../Mobile/ProgressBar'
import PriceBreakdown from '../PriceBreakdown'
import Sidebar from '../Sidebar'
import Summary from '../Summary'

import styled from 'styled-components'

const Wrapper = styled(Box)`
  display: flex;

  .main {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    display: flex;
    padding-left: 6.4rem;

    @media (max-width: 1024px) {
      padding: 0 3.2rem;
    }

    @media (min-width: 1025px) {
      flex: 1;
    }

    @media (max-width: 480px) {
      padding: 0 1.6rem;
    }
  }

  .header {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .sidebar {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .mobile-header {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .mobile-progressbar {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .mobile-footer {
    @media (min-width: 1024px) {
      display: none;
    }
  }
`

const checkHeaderVisibility = path => {
  return true
}

const checkSummaryVisibility = path => {
  if (path === '/success') return false
  if (path === '/payment') return false
  if (path === '/summary') return false
  if (path === '/not-found') return false

  return true
}

const checkPriceBreakdownVisibility = path => {
  if (path === '/payment') return true
  if (path === '/summary') return true
  return false
}

const checkPaddingVisibility = path => {
  if (path === '/start-educational-pathway') return false
  if (path === '/add-another-programme') return false
  if (path === '/parent-guardian-details') return false
  if (path === '/payment') return false
  if (path === '/summary') return false
  return true
}

const Layout = ({ children }) => {
  const { pathname } = useLocation()

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  return (
    <Wrapper component='main'>
      {pathname !== '/not-found' && <Sidebar className='sidebar' />}

      <Box className='main'>
        {!isSmallScreen ? (
          checkHeaderVisibility(pathname) && <Header className='header' />
        ) : (
          <MobileHeader className='mobile-header' />
        )}

        {isSmallScreen && <MobileProgressBar className='mobile-progressbar' />}

        <Box
          sx={{
            display: 'flex',
            flex: '1 1 0%',
            '@media (max-width: 1024px)': {
              display: 'block'
            }
          }}
        >
          <Box className='wrapper'>
            <Box
              sx={{
                paddingRight: checkPaddingVisibility(pathname) ? '32px' : '0px',
                width: '100%',
                height: 'calc(100vh - 88px)',
                overflowY: 'hidden',
                '@media(max-width: 1024px)': {
                  paddingRight: 0,
                  height: 'auto'
                }
              }}
            >
              {children}
            </Box>

            {!isSmallScreen && checkPriceBreakdownVisibility(pathname) && <PriceBreakdown />}
            {!isSmallScreen && checkSummaryVisibility(pathname) && <Summary />}
          </Box>
        </Box>

        <MobileFooter className='mobile-footer' />
      </Box>
    </Wrapper>
  )
}

export default Layout
