import React, { useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery } from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Autocomplete from '../../components/Autocomplete'
import CustomButton from '../../components/Button'
import Form from '../../components/Form'
import Select from '../../components/Select'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import { countries } from '../../constants/participantDetailsData'
import { SchoolService } from '../../services/school'
import { addAcademicDetails } from '../../store/enrolment/enrolment.actions'
import { selectMainMainOrderId, selectOrder, selectStudentDetails } from '../../store/enrolment/enrolment.selectors'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const AcademicDetailsScreen = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const dispatch = useDispatch()

  const currentStudentDetails = useSelector(selectStudentDetails)
  const order = useSelector(selectOrder)
  const orderId = useSelector(selectMainMainOrderId)

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const [schools, setSchools] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddingMode, setIsAddingMode] = useState(false)
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)

  const initialState = useMemo(
    () => ({
      ...currentStudentDetails
    }),
    [currentStudentDetails]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    formState: { errors }
  } = useForm({ defaultValues: initialState })

  const { field: country } = useController({
    name: 'country',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: nameOfSchool } = useController({
    name: 'schoolName',
    control: control,
    rules: {
      required: { value: !isAddingMode, message: 'This field is required' },
      validate: value => {
        if (!schools.find(({ name }) => name === value) && !isAddingMode) {
          return 'Please select a school from the list'
        }
      }
    }
  })

  const { field: nameOfOtherSchool } = useController({
    name: 'otherSchoolName',
    control: control,
    rules: {
      required: { value: isAddingMode ? true : false, message: 'This field is required' }
    }
  })

  const { field: schoolCity } = useController({
    name: 'schoolCity',
    control: control,
    rules: {
      required: { value: isAddingMode ? true : false, message: 'This field is required' }
    }
  })

  const { field: schoolWebsite } = useController({
    name: 'schoolWebsiteLink',
    control: control
  })

  const { field: consultantFullName } = useController({
    name: 'nameOfConsultantOrAdvisor',
    control: control
  })

  const { field: consultantRole } = useController({
    name: 'roleOfConsultant',
    control: control
  })

  const selectCountry = async selectedCountry => {
    const countryForChange = selectedCountry?.label || selectedCountry
    country.onChange(countryForChange)

    if (countryForChange && countryForChange !== country.value) {
      nameOfSchool.onChange('')

      const fetchedSchools = await SchoolService.getSchoolsByCountry(countryForChange)
      const schools = fetchedSchools.length ? fetchedSchools : []

      setSchools(schools)
    }
  }

  const selectSchoolHandler = school => {
    nameOfSchool.onChange(school)
    setIsAutocompleteOpen(school ? school.length >= 2 : false)
  }

  const onSubmit = async data => {
    const paramsUrl = params.get('navigate')
    const navigateUrl = paramsUrl ? `/${paramsUrl}` : `/parent-guardian-details`

    if (isDirty) {
      let newSchool = null

      if (isAddingMode) {
        try {
          const data = await SchoolService.postSchool({
            country: country.value,
            city: schoolCity.value,
            name: nameOfOtherSchool.value
          })
          newSchool = data.name
        } catch (error) {
          if (error?.response?.data?.statusCode === 400) {
            newSchool = nameOfOtherSchool.value
          } else {
            newSchool = ''
          }
        }
      }

      dispatch(
        addAcademicDetails({
          orderId,
          body: { ...data, schoolName: newSchool ? newSchool : nameOfSchool.value, schoolCity: schoolCity.value || '' },
          callback: () => {
            setIsLoading(false)

            navigate(navigateUrl)
          },
          callbackRejected: () => setIsLoading(false)
        })
      )
    } else {
      navigate(navigateUrl)
    }
  }

  useEffect(() => {
    if (country.value) {
      const fetchSchools = async () => {
        const fetchedSchools = await SchoolService.getSchoolsByCountry(country.value)
        const schools = fetchedSchools.length ? fetchedSchools : []
        setSchools(schools)
      }

      fetchSchools()
    }
  }, [])

  useEffect(() => {
    if (schools.length) {
      if (currentStudentDetails?.schoolName) {
        if (!schools.find(({ name }) => name === currentStudentDetails?.schoolName)) {
          setIsAddingMode(true)
          nameOfSchool.onChange('')

          const fetchNotConfirmedSchool = async () => {
            try {
              const school = await SchoolService.getSchool(currentStudentDetails.schoolId)

              nameOfOtherSchool.onChange(school.name)
              schoolCity.onChange(school.city)
            } catch (error) {}
          }

          fetchNotConfirmedSchool()
        }
      }
    }
  }, [schools])

  return (
    <Wrapper>
      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <ListIcon />

            <Typography className='accordion-title'>Summary</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <Summary />
          </AccordionDetails>
        </Accordion>
      )}

      <Typography className='title' component='h1'>
        Student’s Academic Details
      </Typography>

      <Typography className='text'>
        Complete the details for the student who will be taking the Immerse course
      </Typography>

      <Form
        className='form'
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <CustomButton isLight onClick={() => navigate(-1)}>
              Back
            </CustomButton>

            <CustomButton type='submit' disabled={isLoading}>
              Next <ArrowRight />
            </CustomButton>
          </>
        }
      >
        <Box className='form-col'>
          <Autocomplete
            className='form-item'
            error={errors?.country?.message}
            onSelect={selectCountry}
            options={countries}
            placeholder='Country of residence'
            initialValue={country.value}
            initialInputValue={country.value}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading='lazy'
                  width='20'
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=''
                />
                {option.label}
              </Box>
            )}
          />

          <Autocomplete
            onSelect={selectSchoolHandler}
            error={errors?.schoolName?.message}
            initialValue={nameOfSchool.value}
            initialInputValue={nameOfSchool.value}
            open={isAutocompleteOpen}
            onOpen={() => {
              if (!country.value || nameOfSchool.value?.length >= 2) {
                setIsAutocompleteOpen(true)
              }
            }}
            onClose={() => setIsAutocompleteOpen(false)}
            disabled={isAddingMode}
            limitTags={3}
            endAdornmentIcon='search'
            noOptionsText={
              country.value ? (
                <Box className='no-items'>
                  <Typography className='no-items-text'>
                    We can't find any results in our database. Please add your school manually
                  </Typography>

                  <CustomButton
                    className='no-items-button'
                    containerClass='no-items-button-container'
                    onClick={() => {
                      setIsAddingMode(true)
                      setIsAutocompleteOpen(false)
                      nameOfSchool.onChange('')
                    }}
                  >
                    Add another school
                  </CustomButton>
                </Box>
              ) : (
                'Please select a country first'
              )
            }
            className='form-item'
            options={schools.map(({ name }) => name)}
            filterOptions={(options, state) => {
              return options
                .filter(option => {
                  return option.toLowerCase().includes(nameOfSchool.value?.toLowerCase().trim())
                })
                .slice(0, 10)
            }}
            notFromOptions
            placeholder='Search for your school'
          />

          {isAddingMode && (
            <Box className='add-form'>
              <Box className='add-form-header'>
                <Typography className='add-form-header-title' component='h3'>
                  Add school
                </Typography>

                <CustomButton
                  onClick={() => {
                    setIsAddingMode(false)
                    nameOfOtherSchool.onChange('')
                    schoolCity.onChange('')
                    schoolWebsite.onChange('')
                  }}
                  className='add-form-delete'
                  isLight
                >
                  Delete
                </CustomButton>
              </Box>

              <Box className='form'>
                <Box className='form-col'>
                  <Input
                    error={errors?.otherSchoolName?.message}
                    containerClass='form-item'
                    label='School name'
                    placeholder='Enter school name'
                    name='schoolName'
                    value={nameOfOtherSchool.value}
                    onChange={nameOfOtherSchool.onChange}
                  />

                  <Input
                    error={errors?.schoolCity?.message}
                    containerClass='form-item'
                    label='City'
                    placeholder='Enter city'
                    name='schoolCity'
                    value={schoolCity.value}
                    onChange={schoolCity.onChange}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {isAddingMode && (
            <Input
              value={schoolWebsite.value}
              onChange={schoolWebsite.onChange}
              sx={{ width: '100%' }}
              placeholder='School website e.g. https://www.etoncollege.com/'
              containerClass='form-item-full'
            />
          )}

          <Box className='form-col' sx={{ alignItems: 'flex-end' }}>
            <Select
              onChange={consultantRole.onChange}
              options={[
                'Education consultant',
                'Agent',
                'Teacher',
                'School counsellors',
                'Parent',
                'Family',
                'Friend',
                'Web search (e.g. Google)',
                'Social media',
                'Other'
              ]}
              className='form-item'
              label='Were you recommended to Immerse Education?'
              placeholder='Who recommended you?'
              value={consultantRole.value}
              sx={{ '.MuiInputBase-root': { minHeight: '48px', height: '48px' } }}
            />

            <Input
              label='If you were recommended by a person, please share by who below:'
              value={consultantFullName.value}
              onChange={consultantFullName.onChange}
              placeholder='Enter their full name'
              containerClass='form-item-full'
            />
          </Box>
        </Box>
      </Form>
    </Wrapper>
  )
}

export default AcademicDetailsScreen
