import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    main: {
      brown: '#BB945C',
      grayBlue: '#2D4960',
      darkBlue: '#181C2A',
      blue: '#204FFF',
      lightGray: '#F5F5F7',
      white: '#FFFFFF'
    },
    text: {
      black: '#1D1D1D',
      gray: '#DADADA',
      gray2: '#8C8C8C'
    },
    university: {
      aquamarine: '#9ACAB3',
      red: '#EC1E32',
      yellow: '#FDB717',
      pink: '#F26EA7'
    },
    additional: {
      grayMain: '#DADADA',
      graySecondary: '#C9C9C9',
      grayDark: 'rgba(45, 73, 96, 0.5)',
      grayLightMain: '#E7E1E1',
      grayLightSecondary: '#F1EEE9'
    }
  },
  shadows: {
    main: '0px 11px 22px rgba(0, 0, 0, 0.1)',
    8: '0px 11px 22px rgba(0, 0, 0, 0.1)'
  },
  typography: {
    fontFamily: ['Circular', 'serif'].join(','),

    button: {
      fontSize: '1.4rem',
      fontWeight: '500'
    },
    h1: {
      fontSize: '4rem',
      lineHeight: '5.2rem',
      color: '#1D1D1D',

      '@media (max-width: 768px)': {
        fontSize: '2.8rem',
        lineHeight: '3.64rem'
      }
    },
    h3: {
      fontSize: '2.4rem',
      color: '#1D1D1D'
    },
    h4: {
      fontSize: '2rem',
      lineHeight: '2.8rem',
      color: '#1D1D1D'
    },
    h5: {
      fontSize: '1.8rem',
      fontWeight: 500,
      color: '#1D1D1D'
    },
    h6: {
      fontSize: '1.4rem',
      color: '#1D1D1D'
    },
    textMain: {
      fontSize: '1.6rem',
      fontWeight: '450',
      color: '#6F6F6F'
    },
    textSecondary: {
      fontSize: '1.4rem',
      fontWeight: '450',
      color: '#6F6F6F'
    },
    textSmall: {
      fontSize: '1.3rem',
      fontWeight: '450',
      color: '#6F6F6F'
    },

    sidebar: {
      heading: {
        fontSize: '2rem',
        fontWeight: '450',
        color: '#1D1D1D'
      },
      headingGray: {
        fontSize: '2rem',
        fontWeight: '450',
        color: '#8C8C8C'
      },
      text: {
        fontSize: '1.6rem',
        fontWeight: '450',
        color: '#1D1D1D'
      },
      textGray: {
        fontSize: '1.6rem',
        fontWeight: '450',
        color: '#8C8C8C'
      },
      navigation: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: 450,
        color: '#2D4960'
      },
      subtext: {
        fontSize: '1.5rem',
        lineHeight: '2.4rem',
        color: '#A1A1A1'
      }
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 10
        },
        body: {
          fontFamily: ['Circular', 'serif'].join(',')
        },
        'p, h1, h2, h3, h4, h5 ,h6': {
          margin: 0
        },
        ul: {
          listStyle: 'none',
          padding: 0
        },
        a: {
          textDecoration: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '1.4rem 3.2rem',
          minWidth: '16rem',
          backgroundColor: '#181C2A',
          color: '#FFFFFF',
          fontSize: '1.6rem',
          fontWeight: 500,
          lineHeight: '2.4rem',
          borderRadius: '5.2rem',
          textTransform: 'none',
          '&:hover': {
            background: '#2D4960',
            color: '#FFFFFF',
            boxShadow: '0px 11px 22px rgba(0, 0, 0, 0.1)',

            '& > svg': {
              transform: 'translateX(10px)',
              path: {
                fill: '#FFFFFF',
                stroke: '#FFFFFF'
              }
            }
          },
          ':disabled': {
            opacity: 0.5,
            color: '#FFFFFF'
          },
          '& > svg': {
            marginLeft: '.8rem',
            transition: 'transform .15s ease-in-out, box-shadow .15s ease-in-out',

            path: {
              fill: '#FFFFFF'
            }
          },

          '@media (max-width: 468px)': {
            padding: '1.4rem 2.2rem'
          }
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#1d1d1d'
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            fontSize: '1.6rem',
            fontWeight: 450,
            lineHeight: '2.4rem',
            padding: '1.2rem 1.6rem',
            height: 'auto',

            '& .Mui-error': {
              '&::placeholder': {
                color: '#C8102E'
              }
            },

            '&::placeholder': {
              color: '#888888',
              fontWeight: 400,
              opacity: 1
            }
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C9C9C9',
            borderWidth: '1px'
          },
          '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#181C2A',
              borderWidth: '1px'
            }
          },
          '& .Mui-error': {
            color: '#C8102E',
            '.MuiOutlinedInput-input': {
              '&::placeholder': {
                color: '#C8102E'
              }
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C8102E',
              borderWidth: '1px'
            }
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '.8rem'
          }
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          zIndex: '10000',
          fontSize: '1.4rem',
          lineHeight: '2rem',
          background: '#ffffff',
          color: '#1D1D1D',
          borderRadius: '24px',
          padding: '1.6rem 2.4rem',
          boxShadow: '0px 11px 22px 0px rgba(36, 36, 36, 0.10)',

          '.tooltip-header': {
            marginBottom: '4px',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 600
          },

          '.tooltip-text': {
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 300
          }
        },
        arrow: {
          color: '#181C2A'
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          lineHeight: '2rem',
          borderRadius: '.8rem',
          borderColor: '#C0C8CF',

          '.MuiInputBase-input': {
            padding: '1.2rem 4.4rem 1.2rem 1.6rem'
          }
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          marginTop: '1.2rem',
          border: '1px solid #C0C8CF',
          borderRadius: '.8rem',

          '@media (max-width: 768px)': {
            width: '100%'
          },

          '.Mui-selected': {
            color: '#2D4960',
            background: '#F5F5F7 !important'
          },

          '.MuiMenuItem-root:hover': {
            background: '#F5F5F7 !important'
          },

          '.subject-item': {
            fontSize: 16,
            padding: '8px',
            borderRadius: 4,
            fontWeight: 500,
            cursor: 'pointer',

            '&:hover': {
              color: '#2D4960',
              background: '#E9ECEF !important'
            }
          },

          '.select-subjects': {
            padding: '1rem 1.2rem',

            '.back-arrow': {
              borderRadius: '.8rem',
              cursor: 'pointer',
              transition: 'background .2s ease-in-out',

              '&:hover': {
                background: '#F5F5F7'
              }
            },

            '.subjects-container': {
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            },

            '.product-card-container': {
              padding: '0 .5rem',

              '.product-card': {
                cursor: 'pointer',
                transition: 'background .2s ease-in-out',

                '&:hover': {
                  background: '#F5F5F7'
                }
              }
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          padding: '8px',
          borderRadius: 4,
          fontWeight: 450,

          '&:hover': {
            color: '#2D4960',
            background: '#E9ECEF !important'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontWeight: '450',
          color: '#6F6F6F',
          whiteSpace: 'initial',
          marginBottom: '0.8rem'
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          '.MuiAutocomplete-listbox': {
            marginTop: '12px',
            borderRadius: '8px',
            border: '1px solid #C0C8CF',
            backgroundColor: '#ffffff',
            padding: '4px',
            fontSize: '16px',
            color: '#1D1D1D',

            '.MuiAutocomplete-option': {
              padding: '8px',
              borderRadius: '4px'
            },

            '.Mui-focused': {
              color: '#2D4960',
              backgroundColor: '#E9ECEF !important'
            }
          },

          '.MuiAutocomplete-noOptions': {
            marginTop: '12px',
            borderRadius: '8px',
            border: '1px solid #C0C8CF',
            backgroundColor: '#ffffff',
            padding: '12px',
            fontSize: '16px',
            color: '#1D1D1D'
          }
        }
      }
    }
  }
})
